import React from 'react'
import { ArrowLeft, RefreshCw } from 'lucide-react'
import t1 from '../imagenes/Post/t1.png'
import t2 from '../imagenes/Post/t2.png'
const TechnologyItem = ({ title, imageSrc }) => (
  <div className="flex justify-between items-center mb-5 border-b border-gray-500">
    <p className="text-sm prompt-bold pr-2">{title}</p>
    <img src={imageSrc} alt="Technology" className="w-16 h-16  object-cover rounded mb-3" />
  </div>
)

export default function TechnologyCard() {
  return (
    <div className="bg-gray-100 p-4 rounded-lg shadow-md max-w-sm">
      <div className="flex items-center mb-4 ">
        <ArrowLeft className="w-5 h-5 mr-2" />
        <h2 className="text-xl prompt-bold text-center">Tecnología</h2>
      </div>
      
      <TechnologyItem 
        title="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
        imageSrc={t1}
      />
      <TechnologyItem 
        title="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
        imageSrc={t2}
      />
      <TechnologyItem 
        title="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
        imageSrc={t2}
      />
      
     <div className='flex justify-center'>
     <button className="flex items-center prompt-regular">
        <RefreshCw className="w-4 h-4 mr-2" />
        Mira un tema diferente
      </button>
     </div>
    </div>
  )
}