import React from "react";
import f1 from "../imagenes/LandingPage/f1.jpg";
import { Link } from "react-router-dom";
const SecondNews = () => {
  const repeatTimes = 3;
  const content = (
    <div className="mt-12 grid grid-cols-1 md:grid-cols-3 ">
      {/* Sección izquierda con título y noticias */}
      <div className="md:col-span-4">
        <div className="flex justify-between items-center mb-6 border-t border-black pb-2">
          <h2 className="text-xl  mt-4 prompt-bold ">
            ELECCIONES 2024 <span className="text-2xl ml-4">&gt;</span>
          </h2>
        </div>

        {/* Noticias divididas en dos columnas para pantallas medianas */}
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
          <div>
            <Link to="post" className="no-underline text-black">
              <h5 className="text-2xl mb-2 prompt-semibold">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit
              </h5>
            </Link>
            <p className="text-lg mb-3 prompt-regular">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt
            </p>
            <Link
              to="perfil"
              className="no-underline font-semibold text-gray-800"
            >
              <p className="text-sm prompt-regular ">By José Pérez</p>
            </Link>
          </div>

          <div>
            <Link to="post">
              <img
                src={f1}
                alt="Elections 2024"
                className="w-64 h-52 object-cover mb-4 ml-24"
              />
            </Link>
          </div>
        </div>
        <div>
          <div className="border-t border-gray-300"></div>
          <h3 className="prompt-bold text-lg mb-4 mt-2">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit
          </h3>
          <p className="prompt-bold text-lg mb-1 border-t border-gray-300 ">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit
          </p>
        </div>
      </div>
    </div>
  );

  return (
    <>
      {Array.from({ length: repeatTimes }).map((_, index) => (
        <div key={index}>{content}</div>
      ))}
    </>
  );
};

export default SecondNews;
