import React from "react";
import { Search } from "lucide-react";
import Menu from "./Menu";
import Mainnews from "./Mainnews";
import NewsCarousel from "./NewsCarousel";
import OpinionesSection from "./OpinionesSection";
import BannerSection from "./Banner";
import Footer from "./Footer";
import p1 from "../imagenes/p1.jpg";
import p2 from "../imagenes/p2.jpg";
import prueba2 from '../imagenes/prueba2.jpg'
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import left from '../imagenes/Vectores/left.svg'
import right from '../imagenes/Vectores/right.svg'
const LandingPage = () => {
  const carouselItems = [
    {
      image:p1,
      title:  'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
    },
    {
      image:prueba2,
      title: 'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua',
    },
    {
      image:p2,
      title: 'Ut enim ad minim veniam, quis nostrud exercitation ullamco',
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0)

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => 
      prevIndex === 0 ? carouselItems.length - 1 : prevIndex - 1
    )
  }

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => 
      prevIndex === carouselItems.length - 1 ? 0 : prevIndex + 1
    )
  }

  useEffect(() => {
    const timer = setInterval(() => {
      nextSlide()
    }, 5000)

    return () => clearInterval(timer)
  }, [])

  return (
    <div className="position-relative vh-100 font-sans">
        <header className="absolute top-0 left-0 right-0 z-10 p-4">
        <div className="container mx-auto flex justify-between items-center">
          <Search className="w-5 h-5 text-white" />
          <Link to='/' className="no-underline">
          <h1 className="text-center ml-16">
              <span className="inline-block ml-10 font-sans text-white text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl">
                <span className="">c</span>iv
                <span className="">i</span>s
              </span>
            </h1>
          </Link>
          <div className="space-x-2 sm:space-x-4">
            <Link to='login'> 
            <button className="text-xs sm:text-sm md:text-xl prompt-thin text-white">
              Iniciar sesión
            </button>
            </Link>
            <Link to='precios'>
            <button className="prompt-thin bg-red-600 text-white text-xs sm:text-sm md:text-xl px-3 sm:px-3 md:px-4 py-1 md:py-2 rounded">
              Newsletter
            </button>
            </Link>
           
          </div>
        </div>
      </header>
      
      <div className="relative w-full h-[700px] overflow-hidden">
      {carouselItems.map((item, index) => (
        <div
          key={index}
          className={`absolute inset-0 transition-opacity duration-1000 ${
            index === currentIndex ? 'opacity-100' : 'opacity-0'
          }`}
          style={{
            backgroundImage: `url('${item.image}')`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
          <div className="absolute inset-0 bg-black bg-opacity-50" />
        </div>
      ))}


      <div className="absolute inset-0 flex items-center">
        <div className="relative w-full max-w-6xl mx-auto px-16">
          <h2 className="text-white ml-20 prompt-regular text-4xl md:text-5xl font-bold leading-tight max-w-3xl">
            {carouselItems[currentIndex].title}
          </h2>
          <button 
            onClick={prevSlide}
            className="absolute left-0 top-1/2 -translate-y-1/2 w-40 h-10 flex items-center justify-center text-gray-300 "
            aria-label="Previous slide"
          >
           <img src={left}/>
          </button>
          <button 
            onClick={nextSlide}
            className="absolute right-4 top-1/2 -translate-y-1/2 w-40 h-10 flex items-center justify-center text-gray-300 "
            aria-label="Next slide"
          >
             <img src={right}/>
          </button>
        </div>
      </div>
    </div>
      <Menu />
      <Mainnews />
      <NewsCarousel />
      <OpinionesSection />
      <BannerSection />
      <OpinionesSection />
      <NewsCarousel />
      <Footer />

    
    </div>
  );
}

export default LandingPage;
