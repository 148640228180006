import React from "react";
import { Link } from "react-router-dom";
import civis from "../imagenes/civis.png";
export default function BannerSection() {
  return (
    <div className="font-sans bg-civis h-72 flex flex-col items-center justify-center text-white text-center px-4 lg:px-8 pb-8">
      <Link to="/">
        <img src={civis} className="w-52 h-28 object-cover" />
      </Link>
      <p className="text-lg lg:text-2xl mb-8 prompt-regular ">
        La actualidad en un clic
      </p>
      <Link to="precios">
        <button className="bg-red-600 prompt-regular text-white px-3 py-1 rounded text-base lg:text-lg prompt-semibold hover:bg-red-700 transition duration-300">
          Suscríbete por solo $9.99
        </button>
      </Link>
    </div>
  );
}
