import React from 'react'
import Democracia from './Democracia'
import FollowUs from './FollowUs'

const DemocraciaNews = () => {
  return (
    <div className="max-w-7xl mx-auto px-4 py-8">
      <div className="flex flex-col md:flex-row gap-8">
      <Democracia/>
      <FollowUs/>
      </div>
    </div>
  )
}

export default DemocraciaNews