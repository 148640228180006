import React from "react";
import news3 from "../imagenes/News/news3.png";
import { Link } from "react-router-dom";
const Elecciones = () => {
  const NewsItem = ({ title, author, date, imageUrl }) => (
    <div className="mb-8 pb-8 border-b border-black">
      <div className="flex flex-col md:flex-row">
        <div className="md:w-1/2 md:pr-4 mt-5">
          <Link to="post" className="text-black no-underline">
            <h2 className="text-3xl prompt-bold mb-2">{title}</h2>
          </Link>
          <Link to="perfil" className="no-underline">
            <p className="text-sm prompt-regular text-black mt-8">
              By {author}
            </p>
          </Link>
          <p className="text-base prompt-regular text-black mt-2">{date}</p>
        </div>
        <div className="md:w-1/2 mt-4 md:mt-0 ml-28">
          <Link to="post">
            <img
              src={imageUrl}
              alt={title}
              className="w-96 h-64 object-cover"
            />
          </Link>
        </div>
      </div>
    </div>
  );

  return (
    <div className="w-full md:w-2/3 pr-4">
      <h1 className="text-2xl prompt-bold mb-4 pb-2 border-b border-black">
        ELECCIONES
      </h1>
      <NewsItem
        title="Lorem ipsum dolor sit amet, consectetur adipiscing elit"
        author="José Pérez"
        date="Hoy a las 11:30 a.m. UTC"
        imageUrl={news3}
      />
      <NewsItem
        title="Lorem ipsum dolor sit amet, consectetur adipiscing elit"
        author="José Pérez"
        date="Hoy a las 11:30 a.m. UTC"
        imageUrl={news3}
      />
      <NewsItem
        title="Lorem ipsum dolor sit amet, consectetur adipiscing elit"
        author="José Pérez"
        date="Hoy a las 11:30 a.m. UTC"
        imageUrl={news3}
      />
    </div>
  );
};

export default Elecciones;
