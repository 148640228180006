import React from "react";
import { Link } from "react-router-dom";


const Menu = () => {
  return (
    <div className="container mx-auto mt-8 px-4 prompt-extrabold">
      <nav className="flex flex-wrap justify-center space-x-4 md:space-x-24 border-b-2 border-black pb-4 ">
        <Link
          to='/categoria'
          className="text-base md:text-lg font-medium hover:text-gray-600 text-black no-underline"
        >
          Política
        </Link>
        <Link
          to='/categoria'
          className="text-base md:text-lg font-medium hover:text-gray-600 text-black no-underline"
        >
          Opiniones
        </Link>
        <Link
          to='/categoria'
          className="text-base md:text-lg font-medium hover:text-gray-600 text-black no-underline"
        >
          Investigación
        </Link>
        <Link
          to='/categoria'
          className="text-base md:text-lg font-medium hover:text-gray-600 text-black no-underline"
        >
          Tecnología
        </Link>
        <Link
          to='/categoria'
          className="text-base md:text-lg font-medium hover:text-gray-600 text-black no-underline"
        >
          Mundo
        </Link>
        <Link
          to='/categoria'
          className="text-base md:text-lg font-medium hover:text-gray-600 text-black no-underline"
        >
          Deportes
        </Link>
      </nav>
    </div>
  );
};


export default Menu;