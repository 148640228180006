import React from "react";
import Slider from "react-slick";
import nueva from '../imagenes/nueva.png';
import prueba2 from '../imagenes/prueba2.jpg'
import news1 from '../imagenes/News/news1.png'
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import left from '../imagenes/Vectores/left.svg';
import right from '../imagenes/Vectores/right.svg';

interface NewsItem {
  id: number;
  image: string;
  title: string;
  author: string;
}

const newsItems: NewsItem[] = [
  { id: 1, image: nueva, title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit", author: "José Pérez" },
  { id: 2, image: prueba2, title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit", author: "José Pérez" },
];
const ThirdSectionNews: React.FC = () => {
  const CustomArrow: React.FC<{ className?: string; style?: React.CSSProperties; onClick?: () => void; isLeft: boolean }> = ({
    className,
    style,
    onClick,
    isLeft
  }) => (
    <div
      className={`${className} z-10 flex items-center justify-center w-20 h-20 bg-gray-800 rounded-full opacity-75 hover:opacity-100 transition-opacity duration-300 absolute top-1/2 transform -translate-y-1/2`}
      style={{
        ...style,
        display: "block",
        width: '42px',
        [isLeft ? 'left' : 'right']: '-50px'
      }}
      onClick={onClick}
    >
      <img src={isLeft ? left : right} alt={isLeft ? "Previous" : "Next"} className="w-16 h-16" />
    </div>
  );

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <CustomArrow isLeft={false} />,
    prevArrow: <CustomArrow isLeft={true} />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="container mx-auto my-8 px-16">
      <div className="flex justify-between items-center mb-4 border-b border-black pb-4">
        <h2 className="text-xl prompt-bold">
          TECNOLOGÍA <span className="text-2xl ml-4">&gt;</span>
        </h2>
      </div>

      <div className="relative">
        <Slider {...settings}>
          {newsItems.map((item) => (
            <div key={item.id} className="p-4">
              <div className="p-2">
                <Link to="post" className="no-underline text-black">
                  <img
                    src={item.image}
                    alt="TECNOLOGÍA"
                    className="w-full h-48 object-cover mb-2"
                  />
                  <h3 className="text-xl prompt-bold mb-2">
                    {item.title}
                  </h3>
                </Link>
                <Link to="perfil" className="no-underline">
                  <p className="font-normal">
                    <span className="text-black prompt-regular">By {item.author}</span>
                  </p>
                </Link>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};


  const CivisBanner = () => {
    return (
      <div className="bg-[#0a192f] text-white p-14 rounded-lg flex flex-col items-center justify-center min-h-[200px] font-sans mb-5">
        <h2 className="text-7xl font-sans mb-4">civis</h2>
        <p className="text-xl prompt-regular mb-6 text-center">La actualidad en un clic</p>
        <button className="bg-red-600 prompt-regular text-white px-3 py-1 rounded-lg text-lg font-semibold hover:bg-red-700 transition duration-300">
          Suscríbete por solo $9.99
        </button>
      </div>
    );
  };

  // MoreToRead Component
  const MoreToRead = () => {
    const NewsItem = ({ title, author, time, imageSrc }) => (
      <div className="flex space-x-4 mb-4 border-b border-gray-500">
        <div className="flex-grow mt-2">
          <h3 className="prompt-bold text-lg mb-1">{title}</h3>
          <Link to='perfil' className='no-underline text-black'>
          <p className="text-xs prompt-regular mt-4 ">By{author}</p>
          </Link>
          
          <p className="text-sm prompt-regular ">{time}</p>
        </div>
        <Link to='post'>
        <img
          src={imageSrc}
          alt={title}
          className="w-96 h-40 object-contain mb-4"
        />
        </Link>
      
      </div>
    );

    return (
      <div>
        <h2 className="text-xl prompt-bold mb-4 border-t border-black">
          Mas para leer
        </h2>
        <NewsItem
          title="Lorem ipsum dolor sit amet, consectetur adipiscing elit"
          author="José Pérez"
          time="Hoy a las 11:30 a.m. UTC"
          imageSrc={news1}
        />
        <NewsItem
          title="Lorem ipsum dolor sit amet, consectetur adipiscing elit"
          author="José Pérez"
          time="Hoy a las 11:30 a.m. UTC"
          imageSrc={news1}
        />
        <NewsItem
          title="Lorem ipsum dolor sit amet, consectetur adipiscing elit"
          author="José Pérez"
          time="Hoy a las 11:30 a.m. UTC"
          imageSrc={news1}
        />
      </div>
    );
  };

  // Parent Component 3 in 1
  export default function NewsSections() {
    return (
      <div className="max-w-3xl mx-auto mt-8 p-4">
        <ThirdSectionNews />
        <CivisBanner />
        <MoreToRead />
      </div>
    );
  }

