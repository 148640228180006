import React from "react";
import MoreToRead from "./ProfileMoreNews";
import Perfil4  from '../imagenes/Perfil/Perfil4.png';
import { Link } from "react-router-dom";
export default function ProfileNews() {
  return (
    <div className="bg-white p-6 font-sans">
      <div className="max-w-7xl mx-auto">
        <div className="flex flex-col lg:flex-row gap-10 ">
          {/* Left Column */}
          <MoreToRead />

          <div className="hidden lg:block lg:w-px bg-gray-700 mx-4"></div>
          {/* Right Column */}
          <div className="lg:w-1/3 mr-3">
            <h3 className="text-xl prompt-regular mb-4">ESENCIALES DEL DÍA <span className="text-black ml-5">&gt;</span></h3>
            <div className="space-y-4">
              {[...Array(6)].map((_, i) => (
                <div key={i} className="flex items-start">
                  <Link to='post'>
                  <img
                    src={Perfil4}
                    alt="News"
                    className="w-48 h-28 object-cover rounded-lg mr-3"
                  />
                  </Link>
                  
                  <div>
                    <Link to='post' className="no-underline text-black">
                    <h4 className="prompt-bold text-base mb-1 ml-3">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit
                    </h4>
                    </Link>
                    
                    <Link to='perfil' className="no-underline">
                    <p className="text-xs prompt-regular  text-black ml-3">By José Pérez</p>
                    </Link>
                    <p className="text-xs prompt-regular text-red-500 ml-3">Hace 4 horas</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
