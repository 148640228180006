import React from "react";
import SecondNews from "./SecondNews";
import foto1 from "../imagenes/LandingPage/foto1.jpg";
import foto2 from "../imagenes/LandingPage/foto2.jpg";
import { Link } from "react-router-dom";
import newsletter from "../imagenes/LandingPage/newsletter.jpeg";
const EssentialNewsItem = ({ title, excerpt, author, imageSrc }) => (
  <div className="mb-6 font-sans">
    <Link to="post" className="no-underline">
      <img src={imageSrc} alt={title} className="w-80 h-40 object-cover mb-2" />
    </Link>
    <Link to="post" className="no-underline text-black">
      <h3 className="prompt-extrabold text-lg mb-1">{title}</h3>
    </Link>
    <p className="text-base prompt-regular mb-1">{excerpt}</p>
    <Link to="perfil" className="no-underline text-black">
      <p className="prompt-bold prompt-regular ">By {author}</p>
    </Link>
  </div>
);

const SideNewsItem = ({ title, author, imageSrc, date }) => (
  <div className="flex mb-4">
    <Link to="post" className="">
      <img src={imageSrc} alt={title} className="w-36 h-28 object-cover mr-4" />
    </Link>

    <div>
      <Link to="post" className="no-underline text-black">
        <h3 className="prompt-extrabold text-sm mb-1 ml-4">{title}</h3>
      </Link>
      <Link to="perfil" className="no-underline text-black">
        <p className="text-xs prompt-regular ml-4 mt-2 ">By {author}</p>
      </Link>
      <p className="text-xs prompt-thin text-red-500 ml-4">
        {date}Hace 4 horas
      </p>
    </div>
  </div>
);

export default function Mainnews() {
  return (
    <div className="container mx-auto px-4 py-8 mt-14">
      <div className="flex flex-col lg:flex-row">
        {/* Sección principal de noticias esenciales */}
        <div className="lg:w-2/3 lg:pr-8">
          <h2 className="text-2xl mb-10 ml-10 prompt-regular ">
            ESENCIALES DEL DÍA
          </h2>
          <div className="space-y-8">
            {[0, 1].map((row) => (
              <div
                key={row}
                className="grid grid-cols-1 sm:grid-cols-2 gap-6 relative"
              >
                {[1, 2].map((col) => (
                  <div key={row * 2 + col} className="sm:px-10">
                    <EssentialNewsItem
                      title="Lorem ipsum dolor sit amet, consectetur adipiscing elit"
                      excerpt="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt"
                      author="José Pérez"
                      imageSrc={foto1}
                    />
                  </div>
                ))}
                <div className="absolute top-0 bottom-0 left-1/2 w-px bg-gray-300 hidden sm:block" />
              </div>
            ))}
          </div>
          <SecondNews />
        </div>

        {/* Divisor */}
        <div className="hidden lg:block lg:w-px bg-gray-600 mx-4"></div>

        {/* Sección lateral de noticias */}
        <div className="lg:w-1/3 mt-8 lg:mt-0 pl-4 ml-5">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-xl prompt-semibold">
              TENDENCIAS <span className="text-2x1 ml-3">{">"}</span>
            </h2>
          </div>
          {[1, 2, 3, 4, 5, 6].map((i) => (
            <SideNewsItem
              key={i}
              title="Lorem ipsum dolor sit amet, consectetur adipiscing elit"
              author="José Pérez"
              imageSrc={foto2}
            />
          ))}
          {/* Sección de newsletter */}
          
              <div className="mt-28">
                <Link to="precios" className="no-underline">
                  <div className="flex justify-center mb-4">
                    <img
                      src={newsletter}
                      alt="Newsletter"
                      className="w-full h-28 object-cover"
                    />
                  </div>
                </Link>
                <div className="text-start">
                  <Link to="post" className="no-underline text-black">
                    <h3 className="text-lg prompt-semibold mb-2">
                      Mantente Informado: Recibe las Noticias Más Relevantes
                      cada día
                    </h3>
                  </Link>
                  <p className="text-sm prompt-regular mb-4">
                    Obtenga lo mejor de Civis directamente en su bandeja de
                    entrada. Elija los boletines informativos adecuados para
                    usted.
                  </p>
                </div>
              </div>

        </div>
      </div>
    </div>
  );
}
