import React from "react";
import { Link } from "react-router-dom";
import civislogo from "../imagenes/civislogo.png";

const FooterColumn = ({ title, links }) => (
  <div className="w-full sm:w-1/2 lg:w-auto mb-8">
    <h3 className="prompt-medium mb-4 text-base text-left ml-10 ">{title}</h3>
    <ul className="space-y-2">
      {links.map((link, index) => (
        <li key={index} className="text-left lg:text-left ml-3">
          <Link to='categoria' className="prompt-regular text-white hover:text-gray-300 transition-colors duration-200 no-underline">
            {link}
          </Link>
        </li>
      ))}
    </ul>
  </div>
);

const Footer = () => {
  return (
    <footer className="bg-[#0a192f] text-white py-6">
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap -mx-4">
          {/* Logo Section */}
          <div className="w-full lg:w-1/4 px-4 mb-8 lg:mb-0">
            <div className="flex justify-center lg:justify-start">
              <img
                className="w-auto h-auto max-w-xs object-contain"
                src={civislogo}
                alt="Civis Logo"
              />
            </div>
          </div>
          
          {/* Divider */}
          <div className="hidden lg:block w-px bg-gray-600 self-stretch mx-4"></div>
          
          {/* Footer Columns */}
          <div className="w-full lg:w-2/3 px-4">
            <div className="flex flex-wrap -mx-4 justify-center lg:justify-start">
              <div className="w-full sm:w-1/2 lg:w-auto px-4 mb-8 lg:mb-0">
                <FooterColumn
                  title="Secciones"
                  links={[
                    "Política",
                    "Opiniones",
                    "Investigación",
                    "Tecnología",
                    "Mundo",
                    "Deportes",
                  ]}
                />
              </div>
              <div className="w-full sm:w-1/2 lg:w-auto px-4 mb-8 lg:mb-0">
                <FooterColumn
                  title="Más"
                  links={[
                    "Acerca de",
                    "Contacto",
                    "Preguntas frecuentes",
                    "Ayuda",
                    "Políticas de privacidad",
                    "Cookies",
                  ]}
                />
              </div>
            </div>

            {/* Social Icons */}
            <div className="flex justify-center lg:justify-start space-x-16 mt-8 ml-16">
              <a href="#" className="text-4xl">
                <i className="text-white fab fa-facebook-f"></i>
              </a>
              <a href="#" className="text-4xl">
                <i className="text-white fab fa-x-twitter"></i>
              </a>
              <a href="#" className="text-4xl">
                <i className="text-white fab fa-youtube"></i>
              </a>
              <a href="#" className="text-4xl">
                <i className="text-white fab fa-instagram"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;