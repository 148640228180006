import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import nueva from "../imagenes/nueva.png";
import prueba2 from "../imagenes/prueba2.jpg";
import left from "../imagenes/Vectores/left.svg";
import right from "../imagenes/Vectores/right.svg";

interface NewsItem {
  id: number;
  image: string;
  title: string;
  author: string;
}

const newsItems: NewsItem[] = [
  {
    id: 1,
    image: nueva,
    title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
    author: "José Pérez",
  },
  {
    id: 2,
    image: prueba2,
    title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
    author: "José Pérez",
  },
  {
    id: 3,
    image: nueva,
    title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
    author: "José Pérez",
  },
  {
    id: 4,
    image: prueba2,
    title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
    author: "José Pérez",
  },
];

const NewsCarousel: React.FC = () => {
  const CustomArrow: React.FC<{
    className?: string;
    style?: React.CSSProperties;
    onClick?: () => void;
    isLeft: boolean;
  }> = ({ className, style, onClick, isLeft }) => (
    <div
      className={`${className} z-10 flex items-center justify-center w-20 h-20 bg-gray-800 rounded-full opacity-75 hover:opacity-100 transition-opacity duration-300 absolute top-1/2 transform -translate-y-1/2`}
      style={{
        ...style,
        display: "block",
        width: "42px",
        [isLeft ? "left" : "right"]: "-50px",
      }}
      onClick={onClick}
    >
      <img
        src={isLeft ? left : right}
        alt={isLeft ? "Previous" : "Next"}
        className="w-16 h-16"
      />
    </div>
  );

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <CustomArrow isLeft={false} />,
    prevArrow: <CustomArrow isLeft={true} />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <section className="container mx-auto my-8 px-4 ">
      <div className="border-t border-black pb-4 mb-2">
        <h2 className="text-xl prompt-extrabold mt-4">
          TECNOLOGÍA <span className="text-2xl ml-4">&gt;</span>
        </h2>
      </div>

      <div className="relative ">
        <Slider {...settings}>
          {newsItems.map((item, index) => (
            <div key={item.id} className="p-4 relative">
              {index !== 0 && (
                <div className="absolute left-0 top-4 bottom-4 w-px bg-gray-300"></div>
              )}
              <div className="p-2">
                <Link to="post" className="no-underline text-black">
                  <img
                    src={item.image}
                    alt="TECNOLOGÍA"
                    className="w-full h-36 object-cover mb-2"
                  />
                  <h3 className="text-xl prompt-bold ">{item.title}</h3>
                </Link>
                <Link to="perfil" className="no-underline">
                  <p className="text-sm prompt-regular">
                    <span className="text-black">By {item.author}</span>
                  </p>
                </Link>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default NewsCarousel;