import React from "react";
import { Link } from "react-router-dom";

const FollowUs = () => {
  return (
    <div className="w-full md:w-1/3">
      <div className="border-t border-black pt-4 mb-2"></div>

      <h2 className="text-2xl prompt-bold mb-4">SIGUENOS</h2>

      <div className="flex space-x-4 mb-6">
        <a href="#" className="text-4xl">
          <i className="text-black fab fa-facebook-f"></i>
        </a>
        <a href="#" className="text-4xl">
          <i className="text-black fab fa-x-twitter"></i>
        </a>
        <a href="#" className="text-4xl">
          <i className="text-black fab fa-instagram"></i>
        </a>
      </div>

      <div className="">
        <Link to="precios" className="no-underline">
          <div className="bg-orange-600 p-2 h-32 flex justify-center">
            <h2 className="text-center text-2xl sm:text-7xl prompt-thin-italic text-white  mt-4">
              Newsletter
            </h2>
          </div>
        </Link>
        <Link to="post" className="no-underline text-black">
          <p className="text-base prompt-semibold mb-4">
            Mantente Informado: Recibe las Noticias Más Relevantes cada día
          </p>
        </Link>
        <p className="text-sm prompt-regular">
          Obtenga lo mejor de Civis directamente en su bandeja de entrada con
          los boletines informativos adecuados para usted.
        </p>
      </div>
    </div>
  );
};

export default FollowUs;
