import React from "react";
import Perfil3 from "../imagenes/Perfil/Perfil3.png";
import { Link } from "react-router-dom";
const MoreToRead = () => {
  const NewsItem = ({ title, author, time, imageSrc }) => (
    <div className="flex space-x-8 mb-4 border-b border-gray-500">
      <div className="flex-grow">
        <Link to="post" className="no-underline text-black">
          <h3 className="prompt-bold text-2x1 mb-3 mt-9">{title}</h3>
        </Link>
        <Link to="perfil" className="no-underline">
          <p className="text-xs prompt-regular text-black">By {author}</p>
        </Link>
        <p className="text-base prompt-regular text-black">{time}</p>
      </div>
      <Link to="post" className="object-cover">
        <img src={imageSrc} alt={title} className="w-58 h-48 mb-4" />
      </Link>
    </div>
  );

  return (
    <div>
      <h2 className="text-xl font-bold mb-4 border-t border-black"></h2>
      <NewsItem
        title="Lorem ipsum dolor sit amet, consectetur adipiscing elit"
        author="José Pérez"
        time="Hoy a las 11:30 a.m. UTC"
        imageSrc={Perfil3}
      />
      <NewsItem
        title="Lorem ipsum dolor sit amet, consectetur adipiscing elit"
        author="José Pérez"
        time="Hoy a las 11:30 a.m. UTC"
        imageSrc={Perfil3}
      />
      <NewsItem
        title="Lorem ipsum dolor sit amet, consectetur adipiscing elit"
        author="José Pérez"
        time="Hoy a las 11:30 a.m. UTC"
        imageSrc={Perfil3}
      />
      <NewsItem
        title="Lorem ipsum dolor sit amet, consectetur adipiscing elit"
        author="José Pérez"
        time="Hoy a las 11:30 a.m. UTC"
        imageSrc={Perfil3}
      />
      <NewsItem
        title="Lorem ipsum dolor sit amet, consectetur adipiscing elit"
        author="José Pérez"
        time="Hoy a las 11:30 a.m. UTC"
        imageSrc={Perfil3}
      />
      <NewsItem
        title="Lorem ipsum dolor sit amet, consectetur adipiscing elit"
        author="José Pérez"
        time="Hoy a las 11:30 a.m. UTC"
        imageSrc={Perfil3}
      />
      <div className="flex justify-center">
        <button className="mt-4  bg-black text-white py-2 rounded-3xl px-12 text-base prompt-regular">
          Leer más
        </button>
      </div>
    </div>
  );
};

export default MoreToRead;
