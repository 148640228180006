import React from 'react';
import f1 from './../imagenes/Facebook.png'
import google from './../imagenes/google.png'
import apple from './../imagenes/apple.png'
import { Link } from 'react-router-dom';
const Login = () => {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100 font-sans px-4 sm:px-0">
      <div className="bg-white p-4 sm:p-8 rounded-lg shadow-lg w-full max-w-md">
        <Link to='/' className='no-underline text-black'><h1 className="text-4xl sm:text-6xl font-extrabold mb-4 sm:mb-6 text-center">civis</h1></Link>
        <h2 className="text-xl sm:text-2xl mb-2 text-center prompt-semibold">
          Inicia sesión o crea una cuenta
        </h2>
        <p className="text-sm sm:text-base text-center prompt-regular mb-4">
          Ingresa tu e-mail para iniciar sesión
        </p>
        <input
          type="email"
          placeholder="Ingrese su correo"
          className="border border-gray-300 p-2 text-sm sm:text-xl rounded-lg w-full mb-4"
        />
        <button className="bg-black text-white py-2 px-4 rounded-lg w-full mb-4 prompt-regular">
          Sign up with email
        </button>
        <div className="text-center text-sm sm:text-base text-gray-500 mb-4 prompt-regular">
          O continúe con
        </div>
        <button className="bg-white border border-gray-300 text-black py-2 px-4 rounded-lg w-full mb-4 flex items-center justify-start">
          <img src={google} className="ml-2 w-5 sm:w-6" />
          <h3 className="prompt-extrabold ml-10 sm:ml-20 text-sm sm:text-base">
            Continúe con Google
          </h3>
        </button>
        <button className="bg-white border border-gray-300 text-black py-2 px-4 rounded-lg w-full mb-4 flex items-center justify-start">
          <img src={f1} className="w-8 sm:w-11" />
          <h3 className="prompt-extrabold ml-8 sm:ml-16 text-sm sm:text-base">
            Continúe con Facebook
          </h3>
        </button>
        <button className="bg-white border border-gray-300 text-black py-2 px-4 rounded-lg w-full mb-4 flex items-center justify-start">
          <img src={apple} alt="" className="ml-2 w-5 sm:w-6" />
          <p className="ml-10 sm:ml-20 text-sm sm:text-base prompt-extrabold">
            Continúe con Apple
          </p>
        </button>
        <p className="text-center prompt-regular text-gray-500 text-xs sm:text-sm">
          Al hacer clic en Continuar, acepta nuestros{' '}
          <a href="#" className="prompt-semibold text-gray-700">
            Términos de servicio
          </a>{' '}
          y{' '}
          <a href="#" className="prompt-semibold text-gray-700">
            Política de privacidad
          </a>
        </p>
      </div>
    </div>
  );
};


export default Login;
