import React from "react";
import { Bell, Flag, MessageSquare } from "lucide-react";
import TechnologyCard from "./TechnologyCard";
import NewsSections from "./ThirdSectionNews";
import icono from "../imagenes/icono.png";
import Perfil4 from "../imagenes/Perfil/Perfil4.png";
import { Link } from "react-router-dom";
const Comment = ({ author, time, content }) => (
  <div className="flex space-x-4 mb-6 border-b border-gray-500 mr-4">
    <img src={icono} alt="User" className="w-10 h-10 rounded-full mr-3" />
    <div className="flex-grow mb-4">
      <div className="flex justify-between items-center mb-1 prompt-regular">
        <Link to="perfil " className="no-underline text-black">
          <span className="prompt-semibold">{author}</span>
        </Link>

        <button className="text-gray-500 text-sm prompt-regular ">
          <span>
            <Flag className="w-4 h-4 prompt-regular" />
          </span>
          Reportar
        </button>
      </div>
      <span className="prompt-regular text-gray-500 text-sm mb-2 block">
        Hace {time}
      </span>
      <p className="prompt-regular text-gray-700 mb-2">{content}</p>
      <button className="prompt-regular text-gray-500 text-sm flex items-center">
        <MessageSquare className="w-4 h-4 mr-1 prompt-regular" />
        Responder
      </button>
    </div>
  </div>
);

const SidebarItem = ({ title, author, time, imageSrc }) => (
  <div className="flex items-start space-x-7 mr-12">
    <Link to="post">
      <img src={imageSrc} alt={title} className="w-96 h-40 object-contain" />
    </Link>

    <div className="text-left">
      <Link to="post" className="no-underline text-black">
        <h3 className="prompt-bold text-xl mb-1">{title}</h3>
      </Link>

      <Link to="perfil" className="no-underline text-black">
        <p className="text-xs prompt-regular text-gray-500">By {author}</p>
      </Link>
      <p className="text-xs prompt-regular text-gray-500">Hace {time}</p>
    </div>
  </div>
);

export default function CommentSetcion() {
  return (
    <div className="max-w-7xl mx-auto mt-8 p-4">
      <div className="flex flex-col md:flex-row space-y-8 md:space-y-0 md:space-x-24 mr-12">
        <div className="md:w-2/3">
          <div className="max-w-3xl mx-auto mt-1 p-4">
            <div className="flex items-start space-x-4">
              <img
                src={icono}
                alt="User"
                className="w-10 h-10 rounded-full mr-3"
              />
              <div className="flex-grow">
                <p className="prompt-regular mb-2">
                  ¿Qué piensas? Postea un comentario
                </p>
                <input
                  type="text"
                  placeholder="Unirse a la conversación"
                  className="w-full px-3 py-2 bg-gray-300 border border-gray-300 rounded-xl focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
            </div>
          </div>
          <div className="flex justify-end">
            <button className="mt-2  bg-black text-white py-2 rounded-3xl px-4 text-sm prompt-regular">
              Comentar
            </button>
          </div>
          <div className=" pb-4 mb-6">
            <div className="flex space-x-4 mt-4 border-b border-black">
              <h2 className="text-xl prompt-regular">
                Todos los comentarios (320 comentarios)
              </h2>
              <Bell className="w-6 h-6" />
              <button className="prompt-regular">Mas relevantes</button>
              <button className="prompt-regular">Mas recientes</button>
            </div>
          </div>

          <Comment
            author="Robert Fox"
            time="1 hora"
            content="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
          />
          <Comment
            author="Jenny Wilson"
            time="1 hora"
            content="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
          />
          <Comment
            author="Darlene Robertson"
            time="1 hora"
            content="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
          />
          <div className="flex justify-center align-items-center">
            <button className="bg-black rounded-2xl text-white py-1 px-2">
              Ver mas Comentarios
            </button>
          </div>
          <NewsSections />
        </div>

        <div className=" max-w-7xl mx-5 lg:w-1/2">
          <div className="flex justify-between items-center mb-4 border-t border-black">
            <h2 className="text-xl prompt-semibold mt-2 ">
              Mas para leer <span className="text-black ml-4">{">"}</span>
            </h2>
          </div>
          <div className="featured-news flex space-x-5 items-start mb-3">
            <div className="flex-shrink-0">
              <img
                src={Perfil4}
                alt="Featured news image"
                width={300}
                height={200}
                className="w-40 h-36 object-cover"
              />
            </div>
            <div className="flex-grow">
              <h3 className="text-xl prompt-bold ">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit
              </h3>
            </div>
          </div>
          {[...Array(6)].map((_, i) => (
            <div key={i} className="flex items-start">
              <Link to="post">
                <img
                  src={Perfil4}
                  alt="News"
                  className="w-60 h-28 object-cover mr-3"
                />
              </Link>

              <div>
                <Link to="post" className="no-underline text-black">
                  <h4 className="prompt-bold text-xl mb-1 ml-3">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit
                  </h4>
                </Link>

                <Link to="perfil" className="no-underline text-black">
                  <p className="text-xs prompt-regular text-gray-500 ml-3">
                    By José Pérez
                  </p>
                </Link>
                <p className="text-xs prompt-regular text-red-500 ml-3">
                  Hace 4 horas
                </p>
              </div>
            </div>
          ))}
          <TechnologyCard />
        </div>
      </div>
    </div>
  );
}
