import React from 'react'
import Perfil1 from '../imagenes/Perfil/Perfil1.png'
export default function ProfileCard() {
  return (
    <div className="max-w-6xl mx-auto bg-white rounded-lg overflow-hidden mb-6 mt-10">
      <div className="flex mr-10">
        <div className="w-1/3 p-4 ">
          <img
            src={Perfil1}
            alt="Jenny Wilson"
            className="w-32 h-32 rounded-full mx-auto mb-4"
          />
          <div className="flex justify-center space-x-4">
          <a href="#" className="text-4xl">
                <i className="text-black fab fa-facebook-f"></i>
              </a>
              <a href="#" className="text-4xl">
                <i className="text-black fab fa-x-twitter"></i>
              </a>
              <a href="#" className="text-4xl">
                <i className="text-black fab fa-instagram"></i>
              </a>
          </div>
        </div>
        <div className="w-full p-6 bg-gray-200 rounded-lg">
          <h2 className="text-2xl prompt-bold mb-2">Jenny Wilson</h2>
          <p className="text-gray-500 prompt-regular mb-2">Politologo</p>
          <p className="text-xs prompt-regular  mb-4">
            Education: University of Chicago, BA in political science
          </p>
          <p className="text-lg mb-4 prompt-regular">
            Jenny Wilson is a campaign reporter at The Washington Post, where she covers
            campaigns. She previously covered Congress and campaigns for USA Today, National
            Journal Hotline and CNN. As a member of The Post's Next Generation initiative, she is part
            of the newsroom's efforts to engage younger and more diverse audiences both on- and
            off-platform.
          </p>
          <p className="text-sm  prompt-regular">
            Professional Affiliations: Asian American Journalists Association
          </p>
        </div>
      </div>
    </div>
  )
}