import icono from "../imagenes/icono.png";
import Perfil4 from "../imagenes/Perfil/Perfil4.png";
import { Link } from "react-router-dom";
export default function PostNews() {
  return (
    <div className="bg-white p-6 font-sans">
      <div className="max-w-7xl mx-16">
        <div className="flex flex-col lg:flex-row gap-8">
          {/* Left Column */}
          <div className="max-w-3xl mx-auto bg-gray-200 rounded-lg p-8 shadow-md">
            <div className="space-y-6 m-5">
              <p className="text-base prompt-regular">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
              </p>
              <p className="text-base prompt-regular">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
              </p>
            </div>

            <div className="flex justify-between items-center mb-8 py-4 border-t border-b border-gray-950 m-5">
              <h3 className="text-lg m-2">
                <b className="prompt-semibold">Seguir</b>
                <span className="prompt-regular text-lg ml-2">Política</span>
              </h3>
              <button className="bg-black text-white px-4 py-2 rounded-full text-base prompt-semibold flex items-center m-2">
                Seguir +
              </button>
            </div>

            <p className="text-base prompt-regular m-5">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
            </p>

            <div className="flex items-center m-5">
              <img
                src={icono}
                alt="Jenny Wilson"
                className="w-10 h-10 rounded-full mr-3"
              />
              <div>
                <Link to="perfil" className="no-underline text-black">
                  <p className="prompt-semibold">Jenny Wilson</p>
                </Link>
                <p className="text-xs prompt-regular text-gray-500">
                  Politólogo
                </p>
              </div>
            </div>

            <div className="flex flex-wrap text-sm m-5">
              <span className=" mr-3 pr-3 border-r border-gray-800 prompt-regular text-gray-400 last:border-r-0">
                Relacionados
              </span>
              <span className="mr-3 pr-3 border-r border-gray-800 prompt-bold last:border-r-0">
                <b> ONU</b>
              </span>
              <span className="mr-3 pr-3 border-r border-gray-800 prompt-bold last:border-r-0">
                <b> Elecciones 2024</b>
              </span>
              <span className="mr-3 pr-3 last:border-r-0 prompt-bold">
                <b>IA</b>
              </span>
            </div>
          </div>

          {/* Right Column */}
          <div className="lg:w-1/4 mr-14  mt-20">
            <h3 className="text-xl prompt-bold mb-4">TENDENCIAS <span className="ml-5">&gt;</span></h3>
            <div className="space-y-8 ">
              {[...Array(6)].map((_, i) => (
                <div key={i} className="flex items-start">
                  <Link to="post">
                    <img
                      src={Perfil4}
                      alt="News"
                      className="w-60 h-28 object-cover mr-3"
                    />
                  </Link>

                  <div className="">
                    <Link to="post" className="no-underline text-black">
                      <h4 className="prompt-bold text-xl mb-1 ml-3">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit
                      </h4>
                    </Link>

                    <Link to="perfil" className="no-underline text-black">
                      <p className="text-xs prompt-regular text-gray-500 ml-3">
                        By José Pérez
                      </p>
                    </Link>
                    <p className="text-xs prompt-regular text-red-500 ml-3">
                      Hace 4 horas
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
