import React from "react";
import { Search, Share } from "lucide-react";
import Footer from "./Footer";
import ProfileCard from "./ProfileCard";
import ProfileNews from "./ProfileNews";
import { Link } from "react-router-dom";
export default function Perfil() {
  return (
    <div className="min-h-screen bg-white font-sans">
      {/* Header */}
      <header className="bg-civis text-white p-4">
        <div className="container mx-auto flex justify-between items-center">
          <Search className="w-5 h-5" />
          <Link to='/'>
          <h1 className="text-center">
              <span className="inline-block text-white text-2xl ml-36 sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl">
                <span className="font-sans">c</span>iv
                <span className="font-sans">i</span>s
              </span>
            </h1>
          </Link>
          <div className="space-x-4">
          <Link to='login'>
           <button className="text-white prompt-regular text-xs sm:text-sm md:text-lg">Iniciar sesión</button>
            </Link>
            
            <Link to='precios'>
            <button className="bg-red-600 prompt-regular text-white text-xs sm:text-sm md:text-base px-3 sm:px-3 md:px-4 py-1 md:py-2 rounded">
              Newsletter
            </button>
            </Link>
          </div>
        </div>
      </header>
      <ProfileCard />
      <ProfileNews />
      <Footer />
    </div>
  );
}
