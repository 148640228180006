import React from 'react'
import EsencialesPolitica from './EsencialesPolitica'
import Elecciones from './Elecciones'
const PoliticaNews = () => {
  return (
    <div className="max-w-7xl mx-auto px-4 py-8">
      <div className="flex flex-col md:flex-row gap-8">
        <Elecciones/>
        <EsencialesPolitica />
      </div>
    </div>
  )
}

export default PoliticaNews