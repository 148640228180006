import React from "react";
import news1 from "../imagenes/News/news1.png";
import news2 from "../imagenes/News/news2.png";
import foto1 from "../imagenes/LandingPage/foto1.jpg";
import { Link } from "react-router-dom";
const PoliticaSetcion = () => {
  return (
    <section className="container mx-auto my-8 px-5 mt-2">
    <div className="border-t border-black"></div>
    <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 mt-8">
      {/* Main Opinion */}
      <div className="lg:col-span-2">
      <Link to="post">
        <img
          src={foto1}
          alt="Principal Opinión"
          className="w-fit h-auto object-cover mb-4"
        />
         </Link>
         <Link to="post" className="no-underline text-black">
            <h2 className="text-2xl prompt-semibold mb-2 ">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit
            </h2>
          </Link>

          <Link to="perfil" className="no-underline">
            <p className="text-black prompt-regular mb-4">By José Pérez</p>
          </Link>
          <p className=" prompt-bold border-t border-gray-300">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt.
          </p>
      </div>

      {/* Additional Opinions */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {[1, 2, 3, 4].map((item) => (
          <div key={item} className="mb-6">
            <Link to='post' className="no-underline text-black">
            <img
              src={news1}
              alt={`Opinión ${item}`}
              className="w-full h-40 object-cover mb-2"
            />
            <h4 className="prompt-semibold text-lg mb-1">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit
            </h4>
            </Link>
            <Link to="perfil" className="no-underline">
                <p className="text-black text-sm mb-4 prompt-regular ">By José Pérez</p>
              </Link>
          </div>
        ))}
      </div>
    </div>
  </section>
  );
};

export default PoliticaSetcion;
